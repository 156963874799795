import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import $ from '../../styles/global';
import Utils from '../../utils';
import { createStudentGQL } from '../../apollo/instructor';
import Button from '../Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  color: ${$.color.blue3};
  font-family: Dosis Bold;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 128px;
  margin-bottom: ${$.layout().margin4}px;
`;

const Form = styled.div`
  border-radius: ${$.border().radius1}px;
  border: 1px solid ${$.color.gray1};
  width: calc(550px - ${$.layout().padding2 * 2}px);
  padding: ${$.layout().padding2}px;
  box-shadow: ${$.dropShadow.normal};

  > * {
    margin-bottom: ${$.layout().margin4}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Row = styled.div``;

const Label = styled.div`
  font-family: Ruda Bold;
  font-size: 16px;
  text-transform: uppercase;
  color: ${$.color.blue5};
  margin-bottom: ${$.layout().margin1}px;
`;

const Input = styled.input.attrs({ type: 'text' })`
  ${({ error, confirm }) => `
    width: calc(100% - ${$.layout().padding1 * 2}px);
    border: 1px solid ${error ? $.color.red : $.color.gray1};
    border-radius: 5px;
    padding: 6px ${$.layout().padding1}px;
    outline: none;
    color: ${$.color.blue4};
    font-size: 17px;
    line-height: 17px;
    margin-bottom: ${$.layout().margin1}px;
    ${confirm ? `background-color: ${$.color.gray1};` : ``}
    ::placeholder {
      color: ${$.color.gray2};
    }
  `}
`;

const Note = styled.div`
  color: ${$.color.blue4};
  font-size: 15px;
  line-height: 1.2em;
  margin-bottom: ${$.layout().margin1}px;
`;

const Message = styled.div`
  font-size: 15px;
  line-height: 1.2em;
  color: ${$.color.blue3};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-right: ${$.layout().margin1}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ConfirmButton = styled(Button)`
  ${({ disabled }) => `
    ${disabled ? 'pointer-events: none; opacity: 0.5;' : ''}
    padding: 6px 12px;
    font-size: 15px;
  `}
`;

const SubmitButton = styled(ConfirmButton)`
  background-color: ${$.color.green};
  &:hover {
    color: ${$.color.green};
    border: 2px solid ${$.color.green};
  }
`;

const CancelButton = styled(ConfirmButton)`
  background-color: ${$.color.gray2};
  &:hover {
    color: ${$.color.gray2};
    border: 2px solid ${$.color.gray2};
  }
`;

const formStructure = Utils.addKeys([
  {
    name: 'username',
    text: 'username',
    placeholder: 'E.g. alicewong',
    note:
      'The student will login using this name. No spaces, numbers, uppercase characters and special characters allowed.',
  },
  {
    name: 'password',
    text: 'password',
    placeholder: 'E.g. Abc12345^',
    note:
      'Minimum length is 8. Requires 1 uppercase letter, 1 special character, 1 number and 1 lowercase letter.',
  },
  {
    name: 'email',
    text: 'email',
    placeholder: 'E.g. example@gmail.com',
  },
  {
    name: 'name',
    text: 'student name',
    placeholder: 'E.g. Alice Wong',
    note: 'This name will be displayed next to their icon during class.',
  },
]);

/**
 * Validates username field. Ensures that it has no special characters, spaces or numbers.
 * Enforce lowercase as well.
 */
const validUserName = value => {
  const hasSpace = /\s/g.test(value);
  const hasNumbers = /\d/g.test(value);
  const hasSpecialCharacters = /[$&+,:;=?@#|'<>.^*()%!-]/g.test(value);
  const hasUppercaseLetter = /[A-Z]/g.test(value);

  return {
    hasSpace,
    hasNumbers,
    hasSpecialCharacters,
    hasUppercaseLetter,
    valid:
      !hasSpace && !hasNumbers && !hasSpecialCharacters && !hasUppercaseLetter,
  };
};

/**
 * Validates password. Ensures that it has at least 1 special character, 1 number, 1 uppercase and 1 lowercase letter.
 */
const validPassword = value => {
  const hasSpecialCharacters = /[$&+,:;=?@#|'<>.^*()%!-]/g.test(value);
  const hasNumbers = /\d/g.test(value);
  const hasCapitalLetters = /[A-Z]/g.test(value);
  const hasLowerLetters = /[a-z]/g.test(value);
  const lengthIs8 = value.split('').length >= 8;

  return {
    lengthIs8,
    hasSpecialCharacters,
    hasNumbers,
    hasCapitalLetters,
    hasLowerLetters,
    valid:
      lengthIs8 &&
      hasSpecialCharacters &&
      hasNumbers &&
      hasCapitalLetters &&
      hasLowerLetters,
  };
};

/**
 * Validates email address. Regex pulled from Chromium's documentation on email address validation.
 */
const validEmail = email => {
  // eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

/**
 * Listens to changes whenever the user types and:
 * 1. Saves the input value
 * 2. Reset the error message for the input and form.
 */
const handleOnChange = ({ e, name, setFormMessage, setInput }) => {
  const { value = '' } = e.target;

  setFormMessage('');
  setInput(prev => {
    const newPrev = { ...prev };
    let error;

    newPrev[name] = {
      value,
      error: '',
    };

    switch (name) {
      case 'username':
        error = validUserName(value);
        if (!error.valid) {
          newPrev[name].error = `Invalid username.`;
          if (error.hasSpace) {
            newPrev[name].error += ' You added spaces.';
          }
          if (error.hasNumbers) {
            newPrev[name].error += ' You added numbers.';
          }
          if (error.hasSpecialCharacters) {
            newPrev[name].error += ' You added special characters.';
          }
          if (error.hasUppercaseLetter) {
            newPrev[name].error += ' You added capital letters.';
          }
        }
        break;
      case 'password':
        error = validPassword(value);
        if (!error.valid) {
          newPrev[name].error = 'Invalid password.';
          if (!error.lengthIs8) {
            newPrev[name].error += ' Password length must be 8 or more.';
          }
          if (!error.hasLowerLetters) {
            newPrev[name].error += ' No lowercase letters found.';
          }
          if (!error.hasCapitalLetters) {
            newPrev[name].error += ' No capital letters found.';
          }
          if (!error.hasNumbers) {
            newPrev[name].error += ' No numbers found.';
          }
          if (!error.hasSpecialCharacters) {
            newPrev[name].error += ' No special characters found.';
          }
        }
        break;
      case 'email':
        newPrev[name].error = validEmail(value)
          ? ''
          : 'Invalid email. Did you do a typo?';
        break;
      default:
        break;
    }

    return newPrev;
  });
};

/**
 * Validates input values once user clicks the Confirm button.
 */
const handleOnConfirm = ({ setFormMessage, input, setConfirm }) => {
  const username = input.username ? input.username.value : '';
  const password = input.password ? input.password.value : '';
  const email = input.email ? input.email.value : '';

  /**
   * Returns true if 1 or more fields are empty.
   */
  const isEmpty = (() => {
    const arr = Object.keys(input);

    if (arr.length < 4) {
      return true;
    }

    return (
      arr.filter(name => {
        return input[name].value === '';
      }).length > 0
    );
  })();

  /**
   * If everything passes, put all fields into readOnly mode and show Submit & Cancel button.
   */
  if (
    !isEmpty &&
    validEmail(email) &&
    validPassword(password).valid &&
    validUserName(username).valid
  ) {
    setConfirm(true);
    setFormMessage('Please confirm student details before submitting.');
  }
};

const NewStudentForm = () => {
  const [input, setInput] = useState({});
  const [formMessage, setFormMessage] = useState('');
  const [confirm, setConfirm] = useState(false);
  const onError = () => {
    setConfirm(false);

    toast('An error occurred: Student already exists.', {
      position: 'bottom-center',
      className: 'toast-error',
    });
  };
  const [createStudent] = useMutation(createStudentGQL, {
    onCompleted: ({ createStudentRegistry }) => {
      setFormMessage('');

      if (createStudentRegistry === null) {
        onError();
      } else {
        toast(`Student ${input.name.value} successfully added!`, {
          position: 'bottom-center',
          className: 'toast-success',
        });
        setConfirm(false);
        setInput({});
      }
    },
    onError,
  });

  return (
    <Container>
      <Title>Add student to registry form</Title>
      <Form>
        {formStructure.map(({ name, text, note, placeholder = '', key }) => {
          return (
            <Row key={key}>
              <Label>{text}</Label>
              <Input
                value={input[name] ? input[name].value : ''}
                error={input[name] ? !!input[name].error : false}
                confirm={confirm}
                readOnly={confirm}
                placeholder={placeholder}
                onChange={e => {
                  handleOnChange({ e, name, setFormMessage, setInput });
                }}
              />
              {note && <Note>{note}</Note>}
              <Message className="error">
                {input[name] && input[name].error ? input[name].error : ''}
              </Message>
            </Row>
          );
        })}
        <ButtonsContainer>
          <Message>{formMessage}</Message>
          {confirm ? (
            <>
              <CancelButton
                onClick={() => {
                  setFormMessage();
                  setConfirm(false);
                }}
              >
                Cancel
              </CancelButton>
              <SubmitButton
                onClick={() => {
                  setFormMessage('Submitting...');

                  createStudent({
                    variables: {
                      email: input.email.value,
                      username: input.username.value,
                      name: input.name.value,
                      password: input.password.value,
                    },
                  });
                }}
              >
                Submit
              </SubmitButton>
            </>
          ) : (
            <ConfirmButton
              disabled={
                // Disable this button if less than 4 input has values OR
                // if either one of the input has an error or is empty.
                Object.keys(input).length < 4 ||
                Object.keys(input).filter(name => {
                  const obj = input[name];

                  if (obj.error !== '' || obj.value === '') {
                    return true;
                  }

                  return false;
                }).length > 0
              }
              onClick={() => {
                handleOnConfirm({
                  setFormMessage,
                  input,
                  setConfirm,
                });
              }}
            >
              Confirm
            </ConfirmButton>
          )}
        </ButtonsContainer>
      </Form>
    </Container>
  );
};

export default NewStudentForm;
