import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SEO from '../../components/seo';
import Utils from '../../utils';
import NewStudentForm from '../../components/instructor/NewStudentForm';

const NewPage = () => {
  const state = useSelector(({ user }) => {
    return { user };
  });

  /**
   * Entry point. Kick off anyone who is not an instructor.
   */
  useEffect(() => {
    Utils.redirect(state.user.role);
  }, []);

  return (
    <>
      <SEO
        title="New Student | SG Code Campus Online Platform App"
        description="Learn to code in SG Code Campus's very own online learning platform."
      />
      <NewStudentForm />
    </>
  );
};

export default NewPage;
